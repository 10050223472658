/* eslint-disable */
import { useEffect, useState } from "react";
import info from "../assets/info.svg";

interface SwitchProps {
  value: boolean;
  onChange: () => void;
}

const Switch = (props: SwitchProps) => {
  const toggleClass = "transform translate-x-[19px]";

  return (
    <div
      onClick={props.onChange}
      className={`w-[44px] h-[25px] flex items-center ${
        props.value === true ? "bg-[#12B76A]" : "bg-gray-300"
      } rounded-full p-[3px] cursor-pointer`}
    >
      {/* Switch options */}
      <div
        className={`bg-white
        w-[19px] h-[19px] rounded-full shadow-md transition transform
        ${props.value ? toggleClass : null}
        `}
      ></div>
    </div>
  );
};

const DiscloseVideoContent = ({
  discloseVideoContent,
  setDiscloseVideoContent,
  showDiscloseVideoContent,
  setShowDiscloseVideoContent,
}: any) => {
  useEffect(() => {
    // Add a class to disable scrolling when the modal is opened
    document.body.classList.add("modal-open");
    // Remove the class when the modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const [state, setState] = useState<any>(true);

  useEffect(() => {
    if (state === false) {
      setTimeout(function () {
        setDiscloseVideoContent([]);
        setShowDiscloseVideoContent(false);
      }, 150);
    }
  }, [state]);

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      <div
        onClick={() => {
          setState(false);
        }}
        className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"
      ></div>
      <div className="max-w-[500px] w-full p-[24px] bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="w-full flex items-center justify-between">
          <h3 className="h3 leading-[30px]">Disclose video content</h3>
          <Switch
            value={state === false ? state : true}
            onChange={() => setState(false)}
          />
        </div>
        <div className="mt-[24px] w-full bg-white py-[16px] px-[20px] space-x-[12px] flex items-center border-2 border-[#D0D5DD] rounded-xl">
          <img className="h-[16px] w-[16px] relative z-[1]" src={info} />
          <div className="relative z-[1]">
            <p className="text-gray-500 font-light text-[12px] leading-[18px]">
              Your video will be labeled “Promotional Content”. This cannot be
              changed once your video is posted.
            </p>
          </div>
        </div>

        <p className="py-[20px] text-gray-500 font-light text-[12px] leading-[16px]">
          Turn on disclose that this video promotes goods or services in
          exchange for something of value. Your video could promote yourself, a
          third party, or both.
        </p>

        <div className="space-y-[16px]">
          <div className="w-full flex flex-row items-start justify-between">
            <div>
              <h4 className="text-[14px] leading-[20px] font-bold">
                Your Brand
              </h4>
              <div className="relative text-6x">
                <p className="text-gray-500 font-light text-[12px] leading-[18px]">
                  You are promoting yourself or your own business.
                </p>
                <p className="text-gray-500 font-light text-[12px] leading-[18px]">
                  This video will be classified as Brand Organic.
                </p>
              </div>
            </div>
            <input
              onChange={(e) =>
                e.target.checked
                  ? discloseVideoContent.length
                    ? setDiscloseVideoContent((prevState: any) => [
                        ...prevState,
                        "your-brand",
                      ])
                    : setDiscloseVideoContent(["your-brand"])
                  : setDiscloseVideoContent((prevState: any) =>
                      prevState.filter((item: any) => item !== "your-brand")
                    )
              }
              defaultChecked={
                discloseVideoContent.length &&
                discloseVideoContent.includes("your-brand")
              }
              className="cursor-pointer p-0 appearance-none checked:bg-black rounded-2 w-[20px] h-[20px] overflow-hidden"
              type="checkbox"
            />
          </div>

          <div className="w-full flex flex-row items-start justify-between">
            <div>
              <h4 className="text-[14px] leading-[20px] font-bold">
                Branded content
              </h4>
              <div className="relative text-6x">
                <p className="text-gray-500 font-light text-[12px] leading-[18px]">
                  You are promoting yourself or your own business.
                </p>
                <p className="text-gray-500 font-light text-[12px] leading-[18px]">
                  This video will be classified as Brand Organic.
                </p>
              </div>
            </div>
            <input
              onChange={(e) =>
                e.target.checked
                  ? discloseVideoContent.length
                    ? setDiscloseVideoContent((prevState: any) => [
                        ...prevState,
                        "branded-content",
                      ])
                    : setDiscloseVideoContent(["branded-content"])
                  : setDiscloseVideoContent((prevState: any) =>
                      prevState.filter(
                        (item: any) => item !== "branded-content"
                      )
                    )
              }
              defaultChecked={
                discloseVideoContent.length &&
                discloseVideoContent.includes("branded-content")
              }
              className="cursor-pointer p-0 appearance-none checked:bg-black rounded-2 w-[20px] h-[20px] overflow-hidden"
              type="checkbox"
            />
          </div>
        </div>

        <p className="text-black text-[12px] leading-[18px] py-[24px]">
          By posting, you agree to our{" "}
          <span className="text-blue-500">Music Usage Confirmation.</span>
        </p>

        <button
          onClick={() => {
            setShowDiscloseVideoContent(false);
          }}
          className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default DiscloseVideoContent;
