import { initializeApp } from "firebase/app";
import { getStorage, FirebaseStorage } from "firebase/storage";

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const firebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyC2ftDHKZ0k1Qd0_kZginM-oU-IPjZmzmo",
  authDomain: "social-postr-28c4b.firebaseapp.com",
  projectId: "social-postr-28c4b",
  storageBucket: "social-postr-28c4b.appspot.com",
  messagingSenderId: "232881858757",
  appId: "1:232881858757:web:abe03839d68c15d73928ac",
};

const app = initializeApp(firebaseConfig);

const storage: FirebaseStorage = getStorage(app);

export { storage };
