import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <main className="max-w-xl mx-auto">
      <div className="p-5">
        <Link to="/" className="btn btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z"
              clipRule="evenodd"
            />
          </svg>{" "}
          Back
        </Link>
        <h1 className="text-3xl font-extrabold pb-6">Privacy Policy</h1>

        <pre
          className="leading-relaxed whitespace-pre-wrap"
          style={{ fontFamily: "sans-serif" }}
        >
          <span className="font-semibold">Effective Date:</span> July 2, 2024
          <br /> <br />
          Welcome to Arnaud Labs LLC's Privacy Policy. We value your privacy and
          are committed to protecting your personal information. This policy
          explains how we collect, use, and disclose information when you use
          our Social Postr tool (the "Service"). By using the Service, you agree
          to the terms outlined here and to be bound by YouTube's Terms of
          Service and Google's Privacy Policy.
          <br /> <br />
          <h3>Key Definitions</h3>
          <br />
          <ul className="list-disc pl-5">
            <li>
              <span className="font-semibold">Service:</span> The Social Postr
              tool provided by Arnaud Labs LLC.
            </li>
            <li>
              <span className="font-semibold">Personal Data:</span> Information
              that identifies you as an individual.
            </li>
            <li>
              <span className="font-semibold">Usage Data:</span> Information
              collected automatically about how you interact with our Service.
            </li>
            <li>
              <span className="font-semibold">Cookies:</span> Small data files
              stored on your device.
            </li>
            <li>
              <span className="font-semibold">Data Controller:</span> The entity
              that determines how and why personal data is processed.
            </li>
            <li>
              <span className="font-semibold">
                Data Processors (Service Providers):
              </span>{" "}
              Those who process personal data on our behalf.
            </li>
            <li>
              <span className="font-semibold">Data Subject (User):</span> You,
              the individual using our Service.
            </li>
          </ul>
          <br />
          <h3>Information Collection and Use</h3>
          <br />
          We collect various types of data to provide and enhance our Service.
          <br />
          <br />
          <span className="font-semibold">Personal Data</span> When using our
          Service, you may provide us with personal details such as:
          <br />
          <br />
          <ul className="list-disc pl-5">
            <li>
              <span className="font-semibold">Email Address:</span> For
              communication and updates.
            </li>
            <li>
              <span className="font-semibold">Cookies and Usage Data:</span> To
              improve your experience.
            </li>
          </ul>
          <br />
          <span className="font-semibold">Usage Data</span> We automatically
          collect information on how you use the Service, including:
          <br />
          <br />
          <ul className="list-disc pl-5">
            <li>
              <span className="font-semibold">IP Address:</span> Your computer's
              internet address.
            </li>
            <li>
              <span className="font-semibold"> Browser Type and Version:</span>{" "}
              Your web browser details.
            </li>
            <li>
              <span className="font-semibold">IP Address:</span> Your computer's
              internet address.
            </li>
            <li>
              <span className="font-semibold">Pages Visited:</span> The pages
              you view and the time spent on them.
            </li>
            <li>
              <span className="font-semibold">Device Information: </span>{" "}
              Identifiers unique to your devices.
            </li>
          </ul>
          <br />
          <span className="font-semibold">Tracking & Cookies</span> We use
          cookies to personalize and enhance your experience. You can manage
          your cookie preferences through your browser settings. However, some
          Service features may not function properly without cookies.
          <br />
          <br />
          <h3>Use of Data</h3>
          <br />
          Arnaud Labs LLC uses the collected data to:
          <br />
          <br />
          <ul className="list-disc pl-5">
            <li>Provide and improve the Service.</li>
            <li>Notify you about Service changes.</li>
            <li>Enable interactive features you choose to use.</li>
            <li>Offer customer support.</li>
            <li>Analyze Service usage to enhance performance.</li>
            <li>Monitor and prevent technical issues.</li>
          </ul>
          <br />
          <h3>YouTube API Services</h3>
          <br />
          Our Service integrates with YouTube API Services. When you use our
          Service, you are also agreeing to be bound by:
          <br />
          <br />
          <ul className="list-disc pl-5">
            <li>
              <span className="font-semibold">YouTube Terms of Service:</span>{" "}
              <a className="link" href="https://www.youtube.com/t/terms">
                YouTube Terms of Service
              </a>
            </li>
            <li>
              <span className="font-semibold">Google Privacy Policy:</span>{" "}
              <a
                className="link"
                href="https://www.google.com/policies/privacy"
              >
                Google Privacy Policy
              </a>
            </li>
          </ul>
          <br />
          We notify users that our API Client uses YouTube API Services and
          outline how your data will be managed in accordance with these
          policies.
          <br />
          <br />
          <h3>Data Retention and Deletion</h3>
          <br />
          Arnaud Labs LLC will retain your Personal Data only as long as
          necessary for the purposes described in this policy. We will also
          retain Usage Data for internal analysis to improve the Service. Any
          data collected through YouTube API Services will be stored temporarily
          and either refreshed or deleted within 30 days as required.
          <br />
          <br />
          <span className="font-semibold">Deleting Your Data</span> You can
          request the deletion of your data from our Service by contacting us.
          Additionally, you can revoke our access to your data through the
          Google security settings page:{" "}
          <a className="link" href="https://security.google.com/settings/">
            Google Security Settings
          </a>
          .
          <br />
          <br />
          <h3>Data Transfer and Storage</h3>
          <br />
          Your data may be transferred to and stored in regions outside your
          local jurisdiction. We ensure your data is protected and handled
          according to this Privacy Policy regardless of where it is processed.
          <br />
          <br />
          <h3>Sharing Your Data</h3>
          <br />
          We may share your data to:
          <br />
          <br />
          <ul className="list-disc pl-5">
            <li>Comply with legal obligations.</li>
            <li>Protect our rights and property.</li>
            <li>Prevent misuse of our Service.</li>
            <li>Ensure your safety and that of others.</li>
            <li>Defend against legal claims.</li>
          </ul>
          <br />
          <h3>Data Security</h3>
          <br />
          We prioritize the security of your data. However, no method of
          internet transmission or electronic storage is completely secure. We
          strive to use commercially acceptable means to protect your data but
          cannot guarantee absolute security.
          <br />
          <br />
          <h3>Your Rights Under GDPR</h3>
          <br />
          If you are in the European Economic Area (EEA), you have the right to:
          <br />
          <br />
          <ul className="list-disc pl-5">
            <li>Access and update your personal data.</li>
            <li>Request the deletion or restriction of your data.</li>
            <li>Object to certain data processing activities.</li>
          </ul>
          <br />
          <h3>Service Providers</h3>
          <br />
          We may employ third parties to facilitate our Service, provide the
          Service on our behalf, perform Service-related tasks, or assist in
          analyzing Service usage. These Service Providers only have access to
          your data as needed to perform these tasks and are obligated not to
          disclose or use it for other purposes.
          <br />
          <br />
          <h3>Links to Other Sites</h3>
          <br />
          Our Service may include links to external sites. We are not
          responsible for their privacy practices and encourage you to review
          their policies.
          <br />
          <br />
          <h3>Children's Privacy</h3>
          <br />
          Our Service is not intended for individuals under 18. We do not
          knowingly collect personal data from children.
          <br />
          <br />
          <h3>Policy Changes</h3>
          <br />
          We may update this Privacy Policy periodically. We will notify you of
          significant changes by posting the new policy on this page and
          updating the effective date.
          <br />
          <br />
          <h3>Contact Us</h3>
          <br />
          If you have questions about this Privacy Policy, please contact us at:
          <br />
          <br />
          <ul className="list-disc pl-5">
            <li>
              <span className="font-semibold">Email:</span>{" "}
              support@arnaudlabsllc.com
            </li>
            <li>
              <span className="font-semibold">Mail:</span> 6770 Stanford Ranch
              Rd #1242, Roseville, CA 95678
            </li>
          </ul>
        </pre>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
