import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = () => (
  <motion.ul
    style={{
      margin: 0,
      padding: 25,
      position: "absolute",
      top: 100,
      width: 230,
      display: "flex",
      flexDirection: "column",
      gap: 10,
    }}
    variants={variants}
  >
    {links.map((item, index) => (
      <MenuItem item={item} key={index} />
    ))}
  </motion.ul>
);

const links = [
  {
    href: "/",
    label: "Home",
  },
  {
    href: "/resources",
    label: "Resources",
  },
  {
    href: "/pricing",
    label: "Pricing",
  },
  {
    href: "/download",
    label: "Download",
  },
  {
    href: "mailto:contact@socialpostr.com?subject=SocialPostr customer support",
    label: "Contact",
  },
];
