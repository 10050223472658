import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { apiClient } from "../libs/api";
import { UserContext } from "../context";

const ResetPassword = () => {
  const { id, token } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useContext(UserContext);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await apiClient.post("/api/auth/reset-password", {
        id,
        token,
        password,
      });

      const { data } = await apiClient.post("/api/auth/login", {
        email: email,
        password: password,
      });

      setState({
        data: {
          name: data.data.user.name,
          email: data.data.user.email,
          accounts: data.data.user.accounts,
          access_token: data.data.user.access_token,
          refresh_token: data.data.user.access_token,
          posts: data.data.user.posts,
          renewsAt: data.data.user.renewsAt,
          status: data.data.user.status,
          variantName: data.data.user.variantName,
          variantId: data.data.user.variantId,
          customerPortal: data.data.user.customerPortal,
          videosDownloaded: data.data.user.videosDownloaded,
        },
        loading: false,
        error: null,
      });
      localStorage.setItem("accessToken", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);

      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get(
          `/api/auth/reset-password/${id}/${token}`
        );
        setEmail(response.data.email);
      } catch (error) {
        navigate("/reset-password");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="w-full relative overflow-hidden flex flex-col items-center justify-start text-left text-sm text-gray-900 font-text-md-medium">
      <div className="self-stretch flex flex-row items-center justify-start">
        <div className="self-stretch flex-1 flex flex-col items-center justify-between">
          <div className="self-stretch flex flex-col items-center justify-start py-0 px-8">
            <div className="max-w-[360px] w-full flex flex-col items-center justify-start gap-[32px] py-[32px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-11xl">
                <div className="self-stretch relative leading-[38px] font-semibold">
                  Password reset
                </div>
              </div>
              <form
                onSubmit={handleSubmit}
                className="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700"
              >
                <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="relative leading-[20px] font-medium">
                      Email
                    </div>
                    <input
                      type="email"
                      value={email}
                      readOnly
                      className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] py-2.5 px-3.5 gap-[8px] text-base  border border-[#D0D5DD] rounded-lg bg-[#F9FAFB] "
                    />
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="relative leading-[20px] font-medium">
                      Password*
                    </div>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="Password"
                      className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-3.5 gap-[8px] text-base border-[1px] border-solid border-gray-300"
                    />
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base text-white">
                  <button className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-black py-2.5 px-[18px] border-[1px] border-solid font-semibold">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
