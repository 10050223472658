/* eslint-disable */
import { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context";
import { apiClient } from "../libs/api";
import Modal from "../components/AddAccount";
import Calender from "../components/Calender";
import Information from "../components/Information";
import AddAccount from "../components/AddAccount";
import prices from "../resources/prices.json";

interface Account {
  name: string;
  access_token: string;
  platform: string;
  _id: string;
}

interface User {
  email: string | undefined;
  name: string | undefined;
  accounts: Account[] | undefined;
  posts: number | undefined;
  password: string | undefined;
  videosDownloaded: number | undefined;
}

type Field = "name" | "email" | "password";

const Dashboard = () => {
  const navigate = useNavigate();
  const [state, setState] = useContext(UserContext);

  const [user, setUser] = useState<User>({
    name: state.data?.name,
    email: state.data?.email,
    accounts: state.data?.accounts,
    posts: state.data?.posts,
    videosDownloaded: state.data?.videosDownloaded,
    password: "",
  });

  const [addAccount, setAddAccount] = useState<any>(false);
  const [information, setInformation] = useState<any>(false);

  const handleChange = (name: string, value: string): void => {
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogOut = () => {
    setState({ data: null, loading: false, error: null });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/");
  };

  const [editable, setEditable] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);

  const handleUpdate = async () => {
    try {
      await apiClient
        .post("/api/auth/update", {
          name: user.name,
        })
        .then((response) => {
          if (response.data.errors.length) {
            setUser((prevState) => ({
              ...prevState,
              email: state.data?.email,
            }));
            return window.alert(response.data.errors[0].msg);
          }

          setState({
            data: {
              name: response.data.data.user.name,
              email: response.data.data.user.email,
              accounts: response.data.data.user.accounts,
              access_token: response.data.data.user.access_token,
              refresh_token: response.data.data.user.access_token,
              posts: response.data.data.user.posts,
              renewsAt: response.data.data.user.renewsAt,
              status: response.data.data.user.status,
              variantName: response.data.data.user.variantName,
              variantId: response.data.data.user.variantId,
              customerPortal: response.data.data.user.customerPortal,
              videosDownloaded: response.data.data.user.videosDownloaded,
            },
            loading: false,
            error: null,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const revoke = async (_id: string) => {
    try {
      await apiClient
        .post("/api/accounts/revoke", {
          _id,
        })
        .then((response: any) => {
          console.log(response);
          setState({
            data: {
              name: response.data.data.user.name,
              email: response.data.data.user.email,
              accounts: response.data.data.user.accounts,
              access_token: response.data.data.user.access_token,
              refresh_token: response.data.data.user.access_token,
              posts: response.data.data.user.posts,
              renewsAt: response.data.data.user.renewsAt,
              status: response.data.data.user.status,
              variantName: response.data.data.user.variantName,
              variantId: response.data.data.user.variantId,
              customerPortal: response.data.data.user.customerPortal,
              videosDownloaded: response.data.data.user.videosDownloaded,
            },
            loading: false,
            error: null,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const createSession = async () => {
    const { data: response } = await apiClient.post(
      "/api/subscriptions/checkout"
    );

    window.location.href = response.url;
  };

  const [subscription, setSubscription] = useState<any>(null);

  useEffect(() => {
    setSubscription(
      prices
        .find((item) => item.name === state.data?.variantName)
        ?.prices.find((priceOption) => priceOption.ID === state.data?.variantId)
    );
  }, []);

  const [timeSaved, setTimeSaved] = useState<any>(null);
  useEffect(() => {
    const format = () => {
      if (state.data?.posts === undefined) {
        return;
      }
      const minutes = state.data.posts * 5;
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;

      if (days > 0) {
        setTimeSaved(
          <>
            {days} <span className="p">days</span>, {remainingHours}{" "}
            <span className="p">hours</span>,{" "}
            <span className="font-normal p">and</span> {remainingMinutes}{" "}
            <span className="p">minutes</span>
          </>
        );
      } else if (hours > 0) {
        setTimeSaved(
          <>
            {hours} <span className="p">hour(s)</span>{" "}
            <span className="font-normal p">and</span> {remainingMinutes}{" "}
            <span className="p">minutes</span>
          </>
        );
      } else {
        setTimeSaved(
          <>
            {remainingMinutes} <span className="p">minutes</span>
          </>
        );
      }
    };
    format();
  }, [state]);

  return (
    <section>
      <div className="layout space-y-8 py-10">
        <h1 className="h2 lg:text-4xl">Your statistics</h1>
        <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-6">
          <div className="bg-white flex flex-col justify-between h-[110px] basis-full border border-[#D0D5DD] rounded-xl p-[16px]">
            <p className="p text-[#667085]">Total Posts</p>
            <h2 className="h2">{user.posts?.toString()}</h2>
          </div>
          <div className="bg-white flex flex-col justify-between h-[110px] basis-full border border-[#D0D5DD] rounded-xl p-[16px]">
            <p className="p text-[#667085]">Total Accounts</p>
            <h2 className="h2">{user.accounts?.length}</h2>
          </div>
          <div className="bg-white flex flex-col justify-between h-[110px] basis-full border border-[#D0D5DD] rounded-xl p-[16px]">
            <p className="p text-[#667085]">Videos Downloaded</p>
            <h2 className="h2">{user.videosDownloaded}</h2>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-6">
          <div className="w-full">
            <h4 className="h4 mb-6">Manage Account</h4>
            <form className="flex flex-col gap-6">
              <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="name"
                    className="font-normal text-sm text-black"
                  >
                    Name
                  </label>
                  <div
                    onClick={() => {
                      setEditable((prevState) => !prevState);
                      if (editable) {
                        handleUpdate();
                      } else {
                        nameRef.current?.focus();
                      }
                    }}
                    className="flex items-center gap-1 cursor-pointer"
                  >
                    {editable ? (
                      <svg
                        width="11"
                        height="9"
                        viewBox="0 0 11 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 3.60042L2.70281 5.30323L4.97321 7.57364L10.0814 1.33001"
                          stroke="#667085"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.187 2.52986C9.70409 1.91186 10.6337 1.82124 11.2645 2.32782C11.2994 2.35531 12.42 3.22586 12.42 3.22586C13.113 3.64479 13.3283 4.5354 12.8999 5.21506C12.8772 5.25146 6.54162 13.1763 6.54162 13.1763C6.33084 13.4393 6.01088 13.5945 5.66893 13.5982L3.24268 13.6287L2.69601 11.3149C2.61943 10.9895 2.69601 10.6478 2.90679 10.3849L9.187 2.52986Z"
                          stroke="#667085"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.01465 4.0006L11.6495 6.79201"
                          stroke="#667085"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}

                    <p className="text-sm text-[#667085] font-normal">
                      {editable ? "Save" : "Edit"}
                    </p>
                  </div>
                </div>
                <input
                  ref={nameRef}
                  readOnly={!editable}
                  value={user.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  type="text"
                  autoComplete="off"
                  id="name"
                  name="name"
                  className="cursor-auto h-11 py-[10px] px-[12px] border border-[#D0D5DD] rounded-lg bg-[#F9FAFB] outline-none"
                />
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="font-normal text-sm text-black"
                  >
                    Email
                  </label>
                </div>
                <input
                  readOnly={true}
                  value={user.email}
                  type="email"
                  autoComplete="off"
                  id="email"
                  name="email"
                  className="cursor-auto h-11 py-[10px] px-[12px] border border-[#D0D5DD] rounded-lg bg-[#F9FAFB] outline-none"
                />
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                  <label
                    htmlFor="password"
                    className="font-normal text-sm text-black"
                  >
                    Password
                  </label>
                </div>
                <input
                  readOnly={true}
                  type="password"
                  value="**********"
                  autoComplete="off"
                  id="password"
                  name="password"
                  className="cursor-auto h-11 py-[10px] px-[12px] border border-[#D0D5DD] rounded-lg bg-[#F9FAFB] outline-none"
                />
              </div>
            </form>
          </div>
          <div className="bg-white w-full">
            <h4 className="h4 mb-6">Plan</h4>
            <div className="p-4 space-y-6 border border-[#D0D5DD] rounded-xl">
              <div className="flex gap-3 text-base text-[#12B76A] font-semibold">
                <div className="border-[6px] border-[#F9FAFB] bg-[#F2F4F7] rounded-[28px]">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="2-layers">
                      <path
                        id="Icon"
                        d="M1.6665 12.0833L9.99984 16.25L18.3332 12.0833M9.99984 3.75L1.6665 7.91667L9.99984 12.0833L18.3332 7.91667L9.99984 3.75Z"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </div>
                {state.data?.variantName}
              </div>
              <div className="space-y-6">
                <div className="space-y-4">
                  <div>
                    <h3 className="h3 mb-0.5">
                      ${subscription && subscription.price}
                      /month
                    </h3>
                    <p className="font-normal text-sm text-[#667085]">
                      Billed {subscription && subscription.interval}ly.
                    </p>
                  </div>
                  <p className="font-normal text-sm text-black">
                    Your subscription allows up to{" "}
                    {state.data?.variantName === "Basic"
                      ? "4"
                      : state.data?.variantName === "Pro"
                      ? "10"
                      : "unlimited"}{" "}
                    linked accounts. You have used {user.accounts?.length}
                    {state.data?.variantName === "Basic"
                      ? "/4"
                      : state.data?.variantName === "Pro"
                      ? "/10"
                      : ""}{" "}
                    accounts.
                  </p>
                </div>
                <div>
                  <h4 className="h4 text-sm">Next billing date:</h4>
                  <p className="font-normal text-sm text-black">
                    {state.data &&
                      new Date(state.data?.renewsAt).toLocaleString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZoneName: "short",
                      })}
                  </p>
                </div>
              </div>
              <div className="flex md:flex-row flex-col gap-4">
                <button
                  onClick={handleLogOut}
                  className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
                >
                  Log Out
                </button>
                <button
                  role="link"
                  onClick={createSession}
                  className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
                >
                  Manage Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h4 className="h4 mb-6">Linked accounts</h4>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-4">
            {state.data?.accounts &&
              state.data.accounts.map((item, index) => (
                <div
                  key={index}
                  className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl"
                >
                  <div className="flex items-center gap-3">
                    <img
                      className="rounded-full"
                      width="40"
                      src={item.image_url}
                    />
                    <p className="font-xs font-medium text-black">
                      {item.name}
                    </p>
                  </div>
                  <button
                    onClick={() => revoke(item._id)}
                    className="px-[12px] py-[7px] bg-[#FEF3F2] rounded-[32px]"
                  >
                    <p className="font-xs font-medium text-[#F04438]">Revoke</p>
                  </button>
                </div>
              ))}
            <div
              onClick={() => setAddAccount(true)}
              className="cursor-pointer flex items-center justify-center gap-2 py-[26px] border border-[#D0D5DD] rounded-xl bg-[#F9FAFB]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6 12H18"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 6V18"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="font-normal text-sm text-[#667085]">Add Account</p>
            </div>
          </div>
        </div>
        <div>
          <h4 className="h4">Calendar</h4>
          <Calender setInformation={setInformation} />
        </div>
        {addAccount && <AddAccount setModal={setAddAccount} />}
        {information && (
          <Information modal={information} setModal={setInformation} />
        )}
      </div>
    </section>
  );
};

export default Dashboard;
