/* eslint-disable */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TOS from "./pages/TOS";
import Home from "./pages/Home";
import Post from "./pages/Post";
import Pricing from "./pages/Pricing";
import Resources from "./pages/resources/page";
import Article from "./pages/resources/article/page";
import Category from "./pages/resources/category/page";
import Author from "./pages/resources/author/page";
import LayoutResources from "./pages/resources/layout";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PostContext, UserContext } from "./context";
import toast, { Toaster } from "react-hot-toast";
import { apiClient } from "./libs/api";
import dayjs from "dayjs";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import SelectFacebookPage from "./components/SelectFacebookPage";
import Download from "./pages/Download";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { upload } from "./actions/upload";
import LoadingModal from "./components/LoadingModal";

const Verify = ({ platform }: any) => {
  const [, setState] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const verify = async () => {
      if (platform === "facebook" || platform === "instagram") {
        return;
      }

      const code = new URLSearchParams(window.location.search).get("code");

      if (!code) {
        toast.error("Code not found");
        return;
      }

      try {
        const response = await apiClient.post(`/api/${platform}/callback`, {
          code,
        });

        if (response.data.errors.length) {
          window.alert(response.data.errors[0].msg);
          return;
        }

        setState({
          data: {
            name: response.data.data.user.name,
            email: response.data.data.user.email,
            accounts: response.data.data.user.accounts,
            access_token: response.data.data.user.access_token,
            refresh_token: response.data.data.user.access_token,
            posts: response.data.data.user.posts,
            renewsAt: response.data.data.user.renewsAt,
            status: response.data.data.user.status,
            variantName: response.data.data.user.variantName,
            variantId: response.data.data.user.variantId,
            customerPortal: response.data.data.user.customerPortal,
            videosDownloaded: response.data.data.user.videosDownloaded,
          },
          loading: false,
          error: null,
        });

        navigate("/dashboard");
      } catch (error) {
        console.error(error);
      }
    };

    verify();
  }, []);

  if (isLoading) {
    return <LoadingModal />;
  }

  if (platform === "instagram" || platform === "facebook") {
    return <SelectFacebookPage platform={platform} />;
  }

  return null;
};

interface Option {
  name: string;
  id: string | number;
}

interface Platform {
  access_token: string;
  access_token_expiry_date: string;
  image_url: string;
  name: string;
  platform: string;
  refresh_token: string;
  refresh_token_expiry_date: string;
  _id: string;
}

function App() {
  useEffect(() => {
    window.createLemonSqueezy();
  }, []);

  const [post, setPost] = useContext(PostContext);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleChange = async (field: any, value: any) => {
    if (field === "platforms") {
      setPost((prevItem) => ({
        ...prevItem,
        [field]: value,
      }));

      if (value.length > post.platforms.length) {
        const platform = value[value.length - 1].platform;
        const id = value[value.length - 1]._id.toString();

        switch (platform) {
          case "instagram":
            break;
          case "facebook":
            break;
          case "linkedin":
            break;
          case "pinterest":
            const response = await apiClient.post("/api/pinterest/boards", {
              access_token: value[value.length - 1].access_token,
              refresh_token: value[value.length - 1].refresh_token,
              access_token_expiry_date:
                value[value.length - 1].access_token_expiry_date,
              _id: id,
            });
            const pinterestOptions = response.data.map((item: any) => ({
              name: item.name,
              id: item.id,
            }));
            setPost((prevItem) => ({
              ...prevItem,
              requirements: [
                ...prevItem.requirements,
                {
                  name: "Pinterest board",
                  platform: "Pinterest",
                  value: pinterestOptions[0].id,
                  type: "dropdown",
                  options: pinterestOptions,
                  _id: id,
                },
                {
                  name: "Pinterest title",
                  platform: "Pinterest",
                  value: "",
                  type: "input",
                  _id: id,
                },
              ],
            }));
            break;
          case "youtube":
            const youtubeOptions: any = [
              { name: "Public", id: "public" },
              {
                name: "Private",
                id: "private",
              },
              { name: "Unlisted", id: "unlisted" },
            ];
            setPost((prevItem) => ({
              ...prevItem,
              requirements: [
                ...prevItem.requirements,
                {
                  name: "YouTube title",
                  platform: "YouTube",
                  value: "",
                  type: "input",
                  options: youtubeOptions,
                  _id: id,
                },
                {
                  name: "Privacy settings",
                  platform: "YouTube",
                  value: youtubeOptions[0].id,
                  type: "dropdown",
                  options: youtubeOptions,
                  _id: id,
                },
              ],
            }));
            break;
          case "tiktok":
            const tiktokOptions: any = [
              { name: "Public", id: "PUBLIC_TO_EVERYONE" },
              {
                name: "Mutual follows & friends",
                id: "MUTUAL_FOLLOW_FRIENDS",
              },
              { name: "Only for yourself", id: "SELF_ONLY" },
            ];
            setPost((prevItem) => ({
              ...prevItem,
              requirements: [
                ...prevItem.requirements,
                {
                  name: "Privacy settings",
                  platform: "TikTok",
                  value: tiktokOptions[0].id,
                  type: "dropdown",
                  options: tiktokOptions,
                  _id: id,
                },
                {
                  name: "Allow comments",
                  platform: "TikTok",
                  value: false,
                  type: "switch",
                  _id: id,
                },
                {
                  name: "Allow duet",
                  platform: "TikTok",
                  value: false,
                  type: "switch",
                  _id: id,
                },
                {
                  name: "Allow stitch",
                  platform: "TikTok",
                  value: false,
                  type: "switch",
                  _id: id,
                },
              ],
            }));
            break;
        }
      } else {
        setPost((prevPost) => ({
          ...prevPost,
          requirements: prevPost.requirements.filter((requirement) =>
            prevPost.platforms.some(
              (platform) => platform._id === requirement._id
            )
          ),
        }));
      }
    } else {
      setPost((prevItem) => ({
        ...prevItem,
        [field]: value,
      }));
    }
  };

  /*const handlePost = async (schedule: boolean) => {
    if (post.caption.trim() === "") {
      return toast.error("Description is required");
    }

    if (post.format === "video" && !post.thumbnail) {
      return toast.error("Thumbnail is required");
    }

    if (post.platforms.length === 0) {
      return toast.error("Platform selection is required");
    }

    for (let i = 0; i < post.requirements.length; i++) {
      const requirement = post.requirements[i];
      if (String(requirement.value).trim() === "") {
        return toast.error(`${requirement.name} is required`);
      }
    }

    const scheduleDate = dayjs(post.date)
      .set("hour", post.time.hour())
      .set("minute", post.time.minute())
      .set("second", post.time.second());

    const formData = new FormData();
    for (const file of post.files) {
      formData.append("files", file);
    }

    if (post.format === "video") {
      formData.append("thumbnail", post.thumbnail[0]);
    }

    const fetchData = async () => {
      const platforms = encodeURIComponent(
        JSON.stringify(post.platforms.map((item: any) => item._id))
      );

      const requirements = encodeURIComponent(
        JSON.stringify(
          post.requirements.map((item) => {
            return {
              name: item.name,
              platform: item.platform,
              value: item.value,
              _id: item._id,
            };
          })
        )
      );

      toast
        .promise(
          (async () => {
            const response: any = await apiClient.post(
              `/api/publish?platforms=${platforms}&requirements=${requirements}&caption=${post.caption}&schedule=${schedule}&date=${scheduleDate}&thumbnail_timestamp=${post.thumbnail_timestamp}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response.status !== 200) {
              throw new Error("Failed to save data");
            }
          })(),
          {
            loading: "Saving...",
            success: <b>Success!</b>,
            error: <b>Internal server error</b>,
          }
        )
        .then(() => {
          setTimeout(function () {
            window.location.href = "/";

            setPost({
              caption: "",
              date: dayjs(),
              time: dayjs(),
              format: null,
              files: [],
              thumbnail: null,
              thumbnail_timestamp: null,
              platforms: [],
              requirements: [],
            });
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    await fetchData();
  };*/

  const handlePost = async (schedule: boolean) => {
    try {
      const toastId = toast.loading("Starting upload...");

      if (post.caption.trim() === "") {
        toast.dismiss(toastId);
        return toast.error("Description is required");
      }

      if (post.format === "video" && !post.thumbnail) {
        toast.dismiss(toastId);
        return toast.error("Thumbnail is required");
      }

      if (post.platforms.length === 0) {
        toast.dismiss(toastId);
        return toast.error("Platform selection is required");
      }

      for (const requirement of post.requirements) {
        if (String(requirement.value).trim() === "") {
          toast.dismiss(toastId);
          return toast.error(`${requirement.name} is required`);
        }
      }

      const updateToastProgress = (progress: number) => {
        toast.loading(`Saving: ${Math.round(progress)}%`, {
          id: toastId,
        });
      };

      const files = await upload(post.files, updateToastProgress);

      let thumbnail = { fileName: "", url: "" };
      if (post.format === "video" && post.thumbnail) {
        const thumbnailFiles = await upload(
          [post.thumbnail[0]],
          updateToastProgress
        );
        thumbnail = thumbnailFiles[0];
      }

      const images = post.files.filter((file: File) =>
        file.type.startsWith("image")
      );
      const videos = post.files.filter((file: File) =>
        file.type.startsWith("video")
      );

      const format = videos.length
        ? "video"
        : images.length > 1
        ? "carousel"
        : "image";

      const date = dayjs(post.date)
        .set("hour", post.time.hour())
        .set("minute", post.time.minute())
        .set("second", post.time.second());

      await apiClient.post(
        "/api/publish",
        {
          caption: post.caption,
          schedule,
          date,
          files,
          thumbnail,
          format,
          thumbnail_timestamp: post.thumbnail_timestamp,
          platforms: post.platforms.map((item) => item._id),
          requirements: post.requirements,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Saved!", { id: toastId });

      setTimeout(() => {
        window.location.href = "/";
        resetPost();
      }, 2000);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const resetPost = () => {
    setPost({
      caption: "",
      date: dayjs(),
      time: dayjs(),
      format: null,
      files: [],
      thumbnail: null,
      thumbnail_timestamp: null,
      platforms: [],
      requirements: [],
    });
  };

  return (
    <BrowserRouter>
      <Navbar />
      <Toaster />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={<Home post={post} handleChange={handleChange} />}
        />
        <Route path="/post" element={<ProtectedRoute />}>
          <Route
            path="/post"
            element={
              <Post
                post={post}
                handleChange={handleChange}
                handlePost={handlePost}
              />
            }
          />
        </Route>
        <Route path="/dashboard" element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="/youtube/callback" element={<ProtectedRoute />}>
          <Route
            path="/youtube/callback"
            element={<Verify platform="youtube" />}
          />
        </Route>
        <Route path="/linkedin/callback" element={<ProtectedRoute />}>
          <Route
            path="/linkedin/callback"
            element={<Verify platform="linkedin" />}
          />
        </Route>
        <Route path="/pinterest/callback" element={<ProtectedRoute />}>
          <Route
            path="/pinterest/callback"
            element={<Verify platform="pinterest" />}
          />
        </Route>
        <Route path="/tiktok/callback" element={<ProtectedRoute />}>
          <Route
            path="/tiktok/callback"
            element={<Verify platform="tiktok" />}
          />
        </Route>
        <Route path="/instagram/callback" element={<ProtectedRoute />}>
          <Route
            path="/instagram/callback"
            element={<Verify platform="instagram" />}
          />
        </Route>
        <Route path="/facebook/callback" element={<ProtectedRoute />}>
          <Route
            path="/facebook/callback"
            element={<Verify platform="facebook" />}
          />
        </Route>
        <Route
          path="/register"
          element={<Auth isSignupFlow={true} text="Create your account" />}
        />
        <Route
          path="/login"
          element={<Auth isSignupFlow={false} text="Login" />}
        />
        <Route
          path="/resources"
          element={<LayoutResources children={<Resources />} />}
        />
        <Route
          path="/resources/:articleID"
          element={<LayoutResources children={<Article />} />}
        />
        <Route
          path="/resources/category/:categoryID"
          element={<LayoutResources children={<Category />} />}
        />
        <Route
          path="/resources/author/:authorID"
          element={<LayoutResources children={<Author />} />}
        />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/download" element={<Download />} />
        {/*<Route path="*" element={<NotFound />} />*/}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
