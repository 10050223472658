/* eslint-disable */
import { toast } from "react-hot-toast";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context";
import { apiClient } from "../libs/api";

const Download = () => {
  const [state, setState] = useContext(UserContext);
  const [input, setInput] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (
      !(
        state.data?.status === "on_trial" ||
        state.data?.status === "active" ||
        state.data?.status === "cancelled"
      )
    ) {
      return navigate("/pricing");
    }

    const handleDownload = async () => {
      toast.promise(
        (async () => {
          const username = input;
          setInput("");

          const token = localStorage.getItem("accessToken");

          const headers: Record<string, string> = {};
          if (token) {
            headers["Authorization"] = `Bearer ${token}`;
          }

          const response = await fetch(
            `https://social-postr-f5ae303715ec.herokuapp.com/download?username=${username}`,
            {
              method: "POST",
              headers: headers,
            }
          );

          console.log(response)

          if (!response.ok) {
            throw new Error();
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "videos.zip";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);

          const videos = response.headers.get("X-Videos");

          const downloadResponse = await apiClient.post("/api/auth/download", {
            videos,
          });

          setState((prevState: any) => ({
            data: {
              ...prevState.data,
              videosDownloaded:
                downloadResponse.data.data.user.videosDownloaded,
            },
            loading: false,
            error: null,
          }));
        })(),
        {
          loading: "Saving...",
          success: <b>Saved!</b>,
          error: <b>Could not save.</b>,
        }
      );
    };
    await handleDownload();
  };

  return (
    <section className="layout flex flex-col items-center pt-16 sm:pt-[124px]">
      <h1 className="text-center text-3xl sm:text-5xl font-semibold text-black">
        Download all of your videos at once
        <br />
        in full HD with no watermarks.
      </h1>

      <div className="p-[24px] max-w-[612px] w-full mt-[64px] bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="w-full flex items-center justify-between">
          <h3 className="h3 leading-[30px]">TikTok Video Downloader</h3>
        </div>

        <div className="space-y-[12px] py-[32px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
            <div className="relative leading-[20px] font-medium">Username</div>
            <input
              value={input}
              placeholder="Enter your username"
              onChange={(e) => setInput(e.target.value)}
              className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-3.5 gap-[8px] text-base border-[1px] border-solid border-gray-300"
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col gap-4">
          <button
            onClick={() => setInput("")}
            className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
          >
            Continue
          </button>
        </div>
      </div>
    </section>
  );
};

export default Download;
