/* eslint-disable */
import { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";

interface ThumbnailProps {
  post: any;
  setModal: (value: boolean) => void;
  source: string;
  type: string;
  handleChange: (type: string, file: File | Number | null) => void;
}
class CustomFileList implements FileList {
  [index: number]: File;
  length: number;
  private files: File[];

  constructor(files: File[]) {
    this.files = files;
    this.length = files.length;
    files.forEach((file, index) => {
      this[index] = file;
    });
  }

  item(index: number): File | null {
    return this.files[index] || null;
  }

  [Symbol.iterator](): IterableIterator<File> {
    let index = 0;
    const files = this.files;
    return {
      next(): IteratorResult<File> {
        if (index < files.length) {
          return { value: files[index++], done: false };
        } else {
          return { done: true } as IteratorResult<File>;
        }
      },
      [Symbol.iterator](): IterableIterator<File> {
        return this;
      },
    };
  }
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  post,
  setModal,
  source,
  type,
  handleChange,
}) => {
  const [state, setState] = useState<string>("home");

  useEffect(() => {
    // Add a class to disable scrolling when the modal is opened
    document.body.classList.add("modal-open");
    // Remove the class when the modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const setFiles = (file: any) => {
    if (file === null) {
      handleChange("thumbnail", null);
      setModal(false);
      return;
    }

    const types = ["image/jpeg", "image/png"];

    if (!types.includes(file[0].type)) {
      toast.error("Invalid file type");
      setModal(false);
      return;
    } else {
      const video = videoRef.current;
      if (video) {
        handleChange("thumbnail_timestamp", video.currentTime * 1000);
      }
      handleChange("thumbnail", file);
      setModal(false);
    }
  };

  const [sliderValue, setSliderValue] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const updateSlider = () => {
        const value = Math.round((video.currentTime / video.duration) * 100);
        setSliderValue(value);
      };

      video.addEventListener("timeupdate", updateSlider);

      return () => {
        video.removeEventListener("timeupdate", updateSlider);
      };
    }
  }, []);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setSliderValue(value);
    const video = videoRef.current;
    if (video) {
      video.currentTime = (value / 100) * video.duration;
    }
  };

  const handleClick = async () => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // You can choose the desired format here, 'image/png' or 'image/jpeg'
        const mimeType = "image/jpeg"; // Change to 'image/png' if needed

        canvas.toBlob((blob) => {
          if (blob) {
            const fileExtension = mimeType.split("/")[1];
            const file = new File([blob], `thumbnail.${fileExtension}`, {
              type: mimeType,
            });
            const fileList = new CustomFileList([file]);
            setFiles(fileList);
          }
        }, mimeType);
      }
    }
  };

  return (
    <div className="p-3 fixed inset-0 flex justify-center items-center">
      <div
        onClick={() => setModal(false)}
        className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"
      ></div>
      <div className="max-w-[600px] p-3 md:space-y-2 bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <video
          ref={videoRef}
          className="rounded-[12px] max-h-[600px]"
          height="auto"
          id="media"
        >
          <source src={source} type={type} />
        </video>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={handleSliderChange}
          className="w-full cursor-pointer"
        />
        <div className="flex md:flex-row flex-col gap-2 m-0">
          <button
            onClick={() => setModal(false)}
            className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={handleClick}
            className="w-full py-[10px] rounded-lg text-base text-white bg-black font-semibold"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};

interface SelectProps {
  setModal: (value: boolean) => void;
  setFiles: (value: FileList | null) => void;
  source: string;
  type: string;
}

export default Thumbnail;

/*import { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import dayjs from "dayjs";

interface ThumbnailProps {
  post: any;
  setModal: (value: boolean) => void;
  source: string;
  type: string;
  handleChange: (type: string, file: File | null) => void;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  post,
  setModal,
  source,
  type,
  handleChange,
}) => {
  const [state, setState] = useState<string>("home");

  useEffect(() => {
    // Add a class to disable scrolling when the modal is opened
    document.body.classList.add("modal-open");
    // Remove the class when the modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const setFiles = (file: any) => {
    if (file === null) {
      handleChange("thumbnail", null);
      setModal(false);
      return;
    }

    const types = ["image/jpeg", "image/png"];

    if (!types.includes(file[0].type)) {
      toast.error("Invalid file type");
      setModal(false);
      return;
    } else {
      handleChange("thumbnail", file);
      setState("upload");
    }
  };

  return (
    <>
      {state === "home" && (
        <Home
          post={post}
          setState={setState}
          setModal={setModal}
          setFiles={setFiles}
        />
      )}
      {state === "select" && (
        <Select
          setModal={setModal}
          setFiles={setFiles}
          source={source}
          type={type}
        />
      )}
      {state === "upload" && (
        <Upload post={post} setModal={setModal} setFiles={setFiles} />
      )}
    </>
  );
};

interface Option {
  name: string;
  id: string | number;
}

interface Requirement {
  name: string;
  platform: string;
  value: string | null | boolean;
  type: string;
  options?: Option[];
}

interface Post {
  caption: string;
  date: dayjs.Dayjs;
  time: dayjs.Dayjs;
  format: null | string;
  files: any;
  thumbnail: any;
  platforms: string[];
  requirements: Requirement[];
}

interface HomeProps {
  post: Post;
  setState: (type: string) => void;
  setModal: (type: boolean) => void;
  setFiles: (value: FileList | null) => void;
}

const Home: React.FC<HomeProps> = ({ post, setState, setModal, setFiles }) => {
  useEffect(() => {
    // Add a class to disable scrolling when the modal is opened
    document.body.classList.add("modal-open");
    // Remove the class when the modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const inputRef: any = useRef();

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      <div className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"></div>
      <div className="p-[24px] md:space-y-8 max-w-[612px] w-full bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="flex items-center justify-between">
          <h3 className="h3">Thumbnail</h3>
          <svg
            onClick={() => setModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div className="flex md:flex-row flex-col gap-4">
          <button
            onClick={() => setState("select")}
            className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
          >
            Select frame
          </button>
          <button
            onClick={() => inputRef.current.click()}
            className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
          >
            Upload image
          </button>
        </div>
      </div>
      <input
        type="file"
        onChange={(event: any) => setFiles(event.target.files)}
        hidden
        ref={inputRef}
      />
    </div>
  );
};

interface SelectProps {
  setModal: (value: boolean) => void;
  setFiles: (value: FileList | null) => void;
  source: string;
  type: string;
}

class CustomFileList implements FileList {
  [index: number]: File;
  length: number;
  private files: File[];

  constructor(files: File[]) {
    this.files = files;
    this.length = files.length;
    files.forEach((file, index) => {
      this[index] = file;
    });
  }

  item(index: number): File | null {
    return this.files[index] || null;
  }

  [Symbol.iterator](): IterableIterator<File> {
    let index = 0;
    const files = this.files;
    return {
      next(): IteratorResult<File> {
        if (index < files.length) {
          return { value: files[index++], done: false };
        } else {
          return { done: true } as IteratorResult<File>;
        }
      },
      [Symbol.iterator](): IterableIterator<File> {
        return this;
      },
    };
  }
}

const Select: React.FC<SelectProps> = ({
  setModal,
  setFiles,
  source,
  type,
}) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const updateSlider = () => {
        const value = Math.round((video.currentTime / video.duration) * 100);
        setSliderValue(value);
      };

      video.addEventListener("timeupdate", updateSlider);

      return () => {
        video.removeEventListener("timeupdate", updateSlider);
      };
    }
  }, []);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setSliderValue(value);
    const video = videoRef.current;
    if (video) {
      video.currentTime = (value / 100) * video.duration;
    }
  };

  const handleClick = async () => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // You can choose the desired format here, 'image/png' or 'image/jpeg'
        const mimeType = "image/jpeg"; // Change to 'image/png' if needed

        canvas.toBlob((blob) => {
          if (blob) {
            const fileExtension = mimeType.split("/")[1];
            const file = new File([blob], `thumbnail.${fileExtension}`, {
              type: mimeType,
            });
            const fileList = new CustomFileList([file]);
            setFiles(fileList);
          }
        }, mimeType);
      }
    }
  };

  return (
    <div className="p-3 fixed inset-0 flex justify-center items-center">
      <div
        onClick={() => setModal(false)}
        className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"
      ></div>
      <div className="max-w-[600px] p-3 md:space-y-2 bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <video
          ref={videoRef}
          className="rounded-[12px] max-h-[600px]"
          height="auto"
          id="media"
        >
          <source src={source} type={type} />
        </video>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={handleSliderChange}
          className="w-full cursor-pointer"
        />
        <div className="flex md:flex-row flex-col gap-2 m-0">
          <button
            onClick={() => setModal(false)}
            className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={handleClick}
            className="w-full py-[10px] rounded-lg text-base text-white bg-black font-semibold"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};

interface UploadProps {
  post: Post;
  setModal: (type: boolean) => void;
  setFiles: (type: File | null) => void;
}

const Upload: React.FC<UploadProps> = ({ post, setModal, setFiles }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center">
      <div className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"></div>
      <div className="p-[24px] md:space-y-8 bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="flex items-center justify-between">
          <h3 className="h3">Thumbnail</h3>
          <svg
            onClick={() => setFiles(null)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <img
          src={URL.createObjectURL(post.thumbnail[0])}
          className="rounded-[12px] max-h-[600px]"
        />

        <div className="flex md:flex-row flex-col gap-4">
          <button
            onClick={() => setFiles(null)}
            className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={() => setModal(false)}
            className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};

export default Thumbnail;*/
