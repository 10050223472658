/* eslint-disable */
import React, { createContext, useEffect, useState } from "react";
import { apiClient } from "../libs/api";
import dayjs from "dayjs";

interface Account {
  image_url: string | undefined;
  name: string;
  access_token: string;
  platform: string;
  _id: string;
}

interface User {
  data: {
    name: string;
    email: string;
    accounts: AccountInterface[];
    posts: number;
    access_token: string;
    refresh_token: string;
    renewsAt: Date;
    status: string;
    variantName: string;
    variantId: string;
    customerPortal: string;
    videosDownloaded: number;
  } | null;
  error: string | null;
  loading: boolean;
}

interface AccountInterface {
  name: string;
  image_url: string;
  access_token: string;
  refresh_token: string;
  access_token_expiry_date: Date;
  refresh_token_expiry_date: Date;
  id?: string;
  sub?: string;
  platform: string;
  _id: string;
}

const UserContext = createContext<
  [User, React.Dispatch<React.SetStateAction<User>>]
>([{ data: null, loading: true, error: null }, () => {}]);

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>({
    data: null,
    loading: true,
    error: null,
  });

  const token = localStorage.getItem("accessToken");

  const fetchUser = async () => {
    const { data: response } = await apiClient.get("/api/auth/me");

    if (response.data && response.data.user) {
      setUser({
        data: {
          name: response.data.user.name,
          email: response.data.user.email,
          accounts: response.data.user.accounts,
          access_token: response.data.user.access_token,
          refresh_token: response.data.user.access_token,
          posts: response.data.user.posts,
          renewsAt: response.data.user.renewsAt,
          status: response.data.user.status,
          variantName: response.data.user.variantName,
          variantId: response.data.user.variantId,
          customerPortal: response.data.user.customerPortal,
          videosDownloaded: response.data.user.videosDownloaded,
        },
        loading: false,
        error: null,
      });
    } else if (response.data && response.data.errors.length) {
      setUser({
        data: null,
        loading: false,
        error: response.errors[0].msg,
      });
    }
  };

  useEffect(() => {
    if (token) {
      fetchUser();
    } else {
      setUser({
        data: null,
        loading: false,
        error: null,
      });
    }
  }, []);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

interface Option {
  name: string;
  id: string | number;
}

interface Requirement {
  name: string;
  platform: string;
  value: string | null | boolean;
  type: string;
  options?: Option[];
  _id: string;
}

interface Platform {
  access_token: string;
  access_token_expiry_date: string;
  image_url: string;
  name: string;
  platform: string;
  refresh_token: string;
  refresh_token_expiry_date: string;
  _id: string;
}

interface Post {
  caption: string;
  date: dayjs.Dayjs;
  time: dayjs.Dayjs;
  format: null | string;
  files: any;
  thumbnail: any;
  thumbnail_timestamp: any;
  platforms: Platform[];
  requirements: Requirement[];
}

const PostContext = createContext<
  [Post, React.Dispatch<React.SetStateAction<Post>>]
>([
  {
    caption: "",
    date: dayjs(),
    time: dayjs(),
    format: null,
    files: [],
    thumbnail: null,
    thumbnail_timestamp: null,
    platforms: [],
    requirements: [],
  },
  () => {},
]);

const PostProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [post, setPost] = useState<Post>({
    caption: "",
    date: dayjs(),
    time: dayjs(),
    format: null,
    files: [],
    thumbnail: null,
    thumbnail_timestamp: null,
    platforms: [],
    requirements: [],
  });

  return (
    <PostContext.Provider value={[post, setPost]}>
      {children}
    </PostContext.Provider>
  );
};

export { UserContext, UserProvider, PostContext, PostProvider };
