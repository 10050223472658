/* eslint-disable */
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context";
import { apiClient } from "../libs/api";
import pricesData from "../resources/prices.json";
import { motion } from "framer-motion";

interface SwitchProps {
  value: boolean;
  onChange: () => void;
}

const Switch = (props: SwitchProps) => {
  return (
    <div
      style={{
        width: 50,
        height: 30,
        backgroundColor: props.value === true ? "#12B76A" : "#d1d5db",
        display: "flex",
        justifyContent: props.value === true ? "flex-end" : "flex-start",
        borderRadius: 15, // Adjusted borderRadius
        padding: 3, // Adjusted padding
        cursor: "pointer",
        boxSizing: "border-box",
      }}
      onClick={props.onChange}
    >
      <motion.div
        style={{
          width: 24, // Adjusted width
          height: 24, // Adjusted height
          backgroundColor: "white",
          borderRadius: 12, // Adjusted borderRadius
        }}
        layout
        transition={spring}
      />
    </div>
  );
};

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

const Pricing = () => {
  const navigate = useNavigate();
  const [state, setState] = useContext(UserContext);

  console.log(state);

  const [annualPricing, setAnnualPricing] = useState(false);
  const [prices, setPrices] = useState(pricesData);

  const createSession = async (ID: string) => {
    if (
      state.data?.status === "on_trial" ||
      state.data?.status === "active" ||
      state.data?.status === "cancelled"
    ) {
      const { data: response } = await apiClient.post(
        "/api/subscriptions/checkout"
      );

      window.location.href = response.url;
      return;
    }
    const { data: response } = await apiClient.post(
      "/api/subscriptions/session",
      {
        ID,
      }
    );

    window.location.href = response.url;
  };

  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  // Variants for each child
  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section className="overflow-hidden" id="pricing">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="py-[58px] px-8 max-w-5xl mx-auto"
      >
        <div className="flex flex-col text-center items-center w-full">
          <motion.p
            variants={childVariants}
            className="flex items-center justify-center rounded-2xl font-medium text-sm text-center p-0 w-[110px] h-[28px] bg-[#EAECF0]"
          >
            Pricing plans
          </motion.p>
          <motion.h2
            variants={childVariants}
            className="font-semibold lg:text-[48px] text-[30px] my-4 tracking-tight"
          >
            Plans for all sizes
          </motion.h2>
          <motion.p
            variants={childVariants}
            className="font-normal lg:text-[20px] text-base text-[#667085]"
          >
            Simple, transparent pricing that grows with you. Try any plan free
            for 7 days.
          </motion.p>
          <motion.div
            variants={childVariants}
            className="flex justify-center items-center gap-[12px] mt-[40px]"
          >
            <Switch
              value={annualPricing}
              onChange={() => setAnnualPricing((prevState) => !prevState)}
            />
            <p className="font-medium text-base">
              Annual pricing <span className="text-[#12B76A]">(save 40%)</span>
            </p>
          </motion.div>
        </div>

        <motion.div
          variants={childVariants}
          className="relative flex justify-center flex-col xl:flex-row items-center xl:items-stretch gap-[24px] mt-[48px]"
        >
          {prices.map((item: any, index: any) => (
            <div key={index} className="relative w-full max-w-lg ">
              <div
                className="border border-[#EAECF0] shadow-sm md:shadow-lg md:px-[32px] px-[24px] relative flex flex-col items-center h-full z-10 rounded-lg"
                style={{
                  background:
                    "linear-gradient(to top, #F9FAFB 112px, white 112px)",
                }}
              >
                <div className="mt-[32px] flex items-center justify-center rounded-full w-[40px] h-[40px] border-[6px] border-[#F9FAFB] bg-[#F2F4F7]">
                  {item.name === "Basic" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[20px] h-[20px]"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 448 512"
                      width="448"
                      height="512"
                    >
                      {/* !Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc. */}

                      <g>
                        <path
                          id="svg_1"
                          d="m224,256a128,128 0 1 0 0,-256a128,128 0 1 0 0,256zm-45.7,48c-98.5,0 -178.3,79.8 -178.3,178.3c0,16.4 13.3,29.7 29.7,29.7l388.6,0c16.4,0 29.7,-13.3 29.7,-29.7c0,-98.5 -79.8,-178.3 -178.3,-178.3l-91.4,0z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  )}
                  {item.name === "Pro" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[20px] h-[20px]"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 448 512"
                      width="448"
                      height="512"
                    >
                      {/* Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc. */}

                      <g>
                        <path
                          id="svg_1"
                          d="m96,128a128,128 0 1 0 256,0a128,128 0 1 0 -256,0zm94.5,200.2l18.6,31l-33.3,123.9l-36,-146.9c-2,-8.1 -9.8,-13.4 -17.9,-11.3c-70,17.5 -121.9,80.9 -121.9,156.4c0,17 13.8,30.7 30.7,30.7l131.8,0c0,0 0,0 0.1,0l5.4,0l112,0l5.5,0c0,0 0,0 0.1,0l131.7,0c17,0 30.7,-13.8 30.7,-30.7c0,-75.5 -51.9,-138.9 -121.9,-156.4c-8.1,-2 -15.9,3.3 -17.9,11.3l-36,146.9l-33.3,-123.9l18.6,-31c6.4,-10.7 -1.3,-24.2 -13.7,-24.2l-19.8,0l-19.7,0c-12.4,0 -20.1,13.6 -13.7,24.2l-0.1,0z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  )}
                  {item.name === "Business" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[20px] h-[16.384px]"
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 640 512"
                      width="640"
                      height="512"
                    >
                      {/* Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc. */}

                      <g>
                        <path
                          id="svg_1"
                          d="m96,128a128,128 0 1 1 256,0a128,128 0 1 1 -256,0zm-96,354.3c0,-98.5 79.8,-178.3 178.3,-178.3l91.4,0c98.5,0 178.3,79.8 178.3,178.3c0,16.4 -13.3,29.7 -29.7,29.7l-388.6,0c-16.4,0 -29.7,-13.3 -29.7,-29.7zm609.3,29.7l-137.9,0c5.4,-9.4 8.6,-20.3 8.6,-32l0,-8c0,-60.7 -27.1,-115.2 -69.8,-151.8c2.4,-0.1 4.7,-0.2 7.1,-0.2l61.4,0c89.1,0 161.3,72.2 161.3,161.3c0,17 -13.8,30.7 -30.7,30.7zm-177.3,-256c-31,0 -59,-12.6 -79.3,-32.9c19.7,-26.6 31.3,-59.5 31.3,-95.1c0,-26.8 -6.6,-52.1 -18.3,-74.3c18.6,-13.6 41.5,-21.7 66.3,-21.7c61.9,0 112,50.1 112,112s-50.1,112 -112,112z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  )}
                </div>
                <div className="mt-[20px] flex justify-between items-center gap-4">
                  <div>
                    <p className="lg:text-xl font-semibold text-[20px] text-[#12B76A]">
                      {item.name}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <p
                    className={`text-[36px] text-[#101828] tracking-tight font-semibold py-[8px]`}
                  >
                    $
                    {annualPricing
                      ? item.prices[1].price
                      : item.prices[0].price}
                    /month
                  </p>
                </div>
                <p className="font-normal text-base text-[#667085]">
                  Billed {annualPricing ? "annually" : "monthly"}.
                </p>

                <div className="w-full relative flex justify-center flex-col lg:flex-row lg:items-stretch">
                  {item.features && (
                    <ul className="my-[32px] xl:w-[336px] space-y-[16px] leading-relaxed text-base text-[#667085] flex-1 whitespace-nowrap ">
                      {item.features.map((feature: any, i: any) => (
                        <li key={i} className="flex items-center gap-[12px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              width="24"
                              height="24"
                              rx="12"
                              fill="#F2F4F7"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M17.0965 7.39004L9.9365 14.3L8.0365 12.27C7.6865 11.94 7.1365 11.92 6.7365 12.2C6.3465 12.49 6.2365 13 6.4765 13.41L8.7265 17.07C8.9465 17.41 9.3265 17.62 9.7565 17.62C10.1665 17.62 10.5565 17.41 10.7765 17.07C11.1365 16.6 18.0065 8.41004 18.0065 8.41004C18.9065 7.49004 17.8165 6.68004 17.0965 7.38004V7.39004Z"
                              fill="black"
                            />
                          </svg>

                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <button
                  onClick={() =>
                    state.data
                      ? createSession(item.prices[annualPricing ? 1 : 0].ID)
                      : navigate("/register")
                  }
                  className="my-[32px] bg-black text-white text-base font-semibold h-[48px] rounded-lg w-full"
                >
                  {state.data?.status === "on_trial" ||
                  state.data?.status === "active" ||
                  state.data?.status === "cancelled"
                    ? state.data.variantName === item.name
                      ? "Manage Subscription"
                      : "Upgrade"
                    : "Start 7 Day Free Trial"}
                </button>
              </div>
            </div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Pricing;
