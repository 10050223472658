/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { UserContext } from "../context";
import LoadingModal from "../components/LoadingModal";

export const ProtectedRoute = () => {
  const [state, setState] = useContext(UserContext);

  if (state.loading) return <LoadingModal />;

  return state.data?.status === "on_trial" ||
    state.data?.status === "active" ||
    state.data?.status === "cancelled" ? (
    <Outlet />
  ) : (
    <Navigate to="/pricing" />
  );
};
