import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../libs/firebase";

export const upload = async (
  files: File[],
  onProgress: (progress: number) => void
): Promise<{ fileName: string; url: string }[]> => {
  const uploadPromises = files.map((file) => {
    const fileRef = ref(storage, `files/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise<{ fileName: string; url: string }>((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        },
        (error) => {
          reject(error);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve({ fileName: file.name, url });
        }
      );
    });
  });

  const items = await Promise.all(uploadPromises);
  return items;
};