/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { PostContext } from "../context";

interface SwitchProps {
  value: boolean | undefined;
  onChange: () => void;
}

const Switch = (props: SwitchProps) => {
  const toggleClass = "transform translate-x-[19px]";

  return (
    <div
      onClick={props.onChange}
      className={`w-[44px] h-[25px] flex items-center ${
        props.value === true ? "bg-[#12B76A]" : "bg-gray-300"
      } rounded-full p-[3px] cursor-pointer`}
    >
      <div
        className={`bg-white w-[19px] h-[19px] rounded-full shadow-md transition transform ${
          props.value ? toggleClass : null
        }`}
      ></div>
    </div>
  );
};

interface Option {
  name: string;
  id: string | number;
}

interface Requirement {
  name: string;
  platform: string;
  value: string | null | boolean;
  type: string;
  options?: Option[];
}

const Settings = ({
  settings,
  setShowSettings,
  handleChangeRequirement,
  requirements,
}: any) => {
  const [state, setState] = useState(true);
  const [post, setPost] = useContext(PostContext);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const groupedRequirements = requirements.reduce(
    (groups: any, requirement: Requirement) => {
      const platform = requirement.platform;
      if (!groups[platform]) {
        groups[platform] = [];
      }
      groups[platform].push(requirement);
      return groups;
    },
    {}
  );

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      <div
        onClick={() => {
          if (state !== true) {
            //setDiscloseVideoContent(state);
          }
          setShowSettings(false);
        }}
        className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"
      ></div>
      <div className="max-w-[468px] w-full bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="max-h-[80vh] overflow-y-auto">
          {Object.keys(groupedRequirements).map((platform, index) => (
            <div key={index} className="p-[24px]">
              <h3 className="text-[18px] leading-[28px] font-semibold">
                {platform} controls
              </h3>
              {groupedRequirements[platform].map(
                (requirement: Requirement, reqIndex: number) => (
                  <div key={reqIndex} className="mt-[16px]">
                    {requirement.type === "dropdown" ? (
                      <div className="my-[16px]">
                        <h4 className="pt-[24px]">{requirement.name}</h4>
                        <select
                          onChange={(e) =>
                            handleChangeRequirement(
                              requirement.name,
                              requirement.options?.find(
                                (option: Option) => option.id === e.target.value
                              )?.id
                            )
                          }
                          value={
                            requirement.value !== null &&
                            typeof requirement.value !== "boolean"
                              ? requirement.value
                              : ""
                          }
                          className="my-[16px] text-[16px] leading-[24px] font-medium outline-none py-[10px] px-[16px] w-full border-1 border-gray-300 rounded-[8px] bg-gray-50"
                        >
                          {requirement.options?.map(
                            (option: Option, optionIndex: number) => (
                              <option key={optionIndex} value={option.id}>
                                {option.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    ) : requirement.type === "switch" ? (
                      <div className="w-full flex items-center justify-between">
                        <p className="text-[16px] leading-[24px] font-medium">
                          {requirement.name}
                        </p>
                        <Switch
                          value={requirement.value as boolean}
                          onChange={() =>
                            handleChangeRequirement(
                              requirement.name,
                              typeof requirement.value === "boolean"
                                ? !requirement.value
                                : null
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div>
                        <input
                          value={
                            typeof requirement.value === "string"
                              ? requirement.value
                              : ""
                          }
                          className="font-light outline-none p-[16px] w-full border border-[#D0D5DD] rounded-[12px] bg-white"
                          onChange={(e) =>
                            handleChangeRequirement(
                              requirement.name,
                              e.target.value
                            )
                          }
                          placeholder={requirement.name}
                        />
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          ))}
        </div>
        <div className="box-sizing w-full bg-gray-300 h-[1px]"></div>
        <div className="p-[24px]">
          <button
            onClick={() => setShowSettings(false)}
            className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
