/* eslint-disable */
import {
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  addMonths,
  format,
  getDay,
  isSameDay,
} from "date-fns";
import { useState, useMemo, useEffect } from "react";
import { apiClient } from "../libs/api";
import { useContext } from "react";
import { UserContext } from "../context";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import youtube from "../assets/youtube.svg";
import tiktok from "../assets/tiktok.svg";
import pinterest from "../assets/pinterest.svg";
import linkedin from "../assets/linkedin.svg";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

interface Event {
  date: Date;
  caption: string;
  accounts: string[];
}

type PlatformImages = {
  [key: string]: string;
};

const platformImages: PlatformImages = {
  facebook: facebook,
  instagram: instagram,
  youtube: youtube,
  tiktok: tiktok,
  pinterest: pinterest,
  linkedin: linkedin,
};

const Calender = ({ setInformation }: any) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [state, setState] = useContext(UserContext);
  const [modal, setModal] = useState<any>(false);

  useEffect(() => {
    apiClient.get("/api/publish").then((res) =>
      setEvents(
        res.data.posts.map((post: any) => {
          return {
            date: new Date(post.date),
            caption: post.caption,
            accounts: post.accounts,
            _id: post._id,
          };
        })
      )
    );
  }, [state]);

  const [currentDate, setCurrentDate] = useState(new Date());

  const nextMonth = () => {
    setCurrentDate((prevDate) => addMonths(prevDate, 1));
  };

  const lastMonth = () => {
    const date = new Date();
    if (currentDate > date) {
      setCurrentDate((prevDate) => addMonths(prevDate, -1));
    }
  };

  const firstDayOfMonth = startOfMonth(currentDate);
  const lastDayOfMoth = endOfMonth(currentDate);

  const daysInMonth = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMoth,
  });

  const startingDayIndex = getDay(firstDayOfMonth);
  const remainingDays = 7 - ((daysInMonth.length + startingDayIndex) % 7);

  const eventsByDate = useMemo(() => {
    return events.reduce((acc: { [key: string]: Event[] }, event) => {
      const dateKey = format(event.date, "yyyy-MM-dd");
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(event);
      return acc;
    }, {});
  }, [events]);

  return (
    <div className="flex flex-col border border-[#D0D5DD] rounded-xl">
      <div className="flex items-center justify-between p-[16px]">
        <h3 className="h3">{format(currentDate, "MMMM yyyy")}</h3>
        <div className="flex gap-[16px]">
          <div
            className={`cursor-pointer flex items-center justify-center p-[7px] rounded-[58px] ${
              isSameDay(currentDate, new Date()) ? "bg-gray-50" : "bg-gray-100"
            }`}
          >
            <svg
              onClick={lastMonth}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00163 0.71811C9.37537 0.32692 10 0.32692 10.3738 0.71811C10.7242 1.08485 10.7242 1.6623 10.3738 2.02904L5.62459 7L10.3738 11.971C10.7242 12.3377 10.7242 12.9152 10.3738 13.2819C10 13.6731 9.37537 13.6731 9.00163 13.2819L3 7L9.00163 0.71811Z"
                fill={isSameDay(currentDate, new Date()) ? "#98A2B3" : "black"}
              />
            </svg>
          </div>
          <div className="cursor-pointer flex items-center justify-center p-[7px] bg-gray-100 rounded-[58px]">
            <svg
              onClick={nextMonth}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.99837 0.71811C4.62463 0.32692 3.99996 0.32692 3.62622 0.71811C3.27584 1.08485 3.27584 1.6623 3.62622 2.02904L8.37541 7L3.62622 11.971C3.27584 12.3377 3.27584 12.9152 3.62622 13.2819C3.99996 13.6731 4.62463 13.6731 4.99837 13.2819L11 7L4.99837 0.71811Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-[#D0D5DD]"></div>
      <div className="py-[16px] flex items-center justify-between [&>p]:flex [&>p]:justify-center [&>p]:w-full [&>p]:font-normal [&>p]:text-xs [&>p]:text-[#667085]">
        {weekdays.map((item, index) => {
          return (
            <p key={index}>
              {window.innerWidth < 1000 ? item.substring(0, 3) : item}
            </p>
          );
        })}
      </div>
      <div className="grid grid-cols-7">
        {Array.from({ length: startingDayIndex }).map((_, index) => {
          return (
            <div
              className="p-[4px] h-[193px] border border-[#D0D5DD] bg-white"
              key={`empty-start-${index}`}
            />
          );
        })}
        {daysInMonth.map((item, index) => {
          const dateKey = format(item, "yyyy-MM-dd");
          const todaysEvents = eventsByDate[dateKey] || [];

          return (
            <div
              key={index}
              className="p-[4px] h-[193px] overflow-auto border border-[#D0D5DD] bg-white"
            >
              <p className="p-[12px] font-xs font-normal text-black">
                {format(item, "d")}
              </p>
              <div className="flex flex-col gap-2">
                {todaysEvents.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setInformation(item)}
                      className="shadow-sm cursor-pointer p-[12px] flex flex-col gap-[8px] border border-[#EAECF0] rounded-lg bg-[#FCFCFD]"
                    >
                      <p className="text-md text-[black] font-medium ">
                        {item.caption}
                      </p>
                      <div className="flex gap-[8px]">
                        {item.accounts.map((item, index) => {
                          const account = state?.data?.accounts.find(
                            (acc) => acc._id === item
                          );

                          const platformImage =
                            // @ts-ignore
                            platformImages[account?.platform];

                          return (
                            <div key={index}>
                              <img src={platformImage} width={24} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {Array.from({ length: remainingDays }).map((_, index) => {
          return (
            <div
              className="p-[4px] h-[193px] border border-[#D0D5DD] bg-white"
              key={`empty-start-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Calender;
