/* eslint-disable */
import { useState, useCallback, useContext, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { UserContext } from "../context";
import { apiClient } from "../libs/api";
import toast from "react-hot-toast";

interface AuthProps {
  text: string;
  isSignupFlow: boolean;
}

type Field = "name" | "email" | "password";

const Auth = ({ text, isSignupFlow }: AuthProps) => {
  useEffect(() => {
    if (state) {
      redirect("/");
    }
  }, []);

  const navigate = useNavigate();

  const [mode, setMode] = useState("home");

  const [state, setState] = useContext(UserContext);

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    otp: "",
  });

  const handleChange = (field: Field, value: string) => {
    setUser((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const verify = async (e: any) => {
    e.preventDefault();

    const { data: signUpData } = await apiClient.post("/api/auth/signup", {
      name: user.name,
      email: user.email,
      password: user.password,
      otp: user.otp,
    });
    const response = signUpData;

    setState({
      data: {
        name: response.data.user.name,
        email: response.data.user.email,
        accounts: response.data.user.accounts,
        access_token: response.data.user.access_token,
        refresh_token: response.data.user.access_token,
        posts: response.data.user.posts,
        renewsAt: response.data.user.renewsAt,
        status: response.data.user.status,
        variantName: response.data.user.variantName,
        variantId: response.data.user.variantId,
        customerPortal: response.data.user.customerPortal,
        videosDownloaded: response.data.user.videosDownloaded,
      },
      loading: false,
      error: null,
    });
    localStorage.setItem("accessToken", response.data.token);
    localStorage.setItem("refreshToken", response.data.refreshToken);

    navigate("/dashboard");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      let response;
      if (isSignupFlow) {
        if (!user.name.trim().length) {
          return toast.error("Invalid name");
        }
        if (!user.email.trim().length) {
          return toast.error("Invalid email");
        }
        if (user.password.trim().length < 8) {
          return toast.error("Invalid password");
        }
        await apiClient
          .post("/api/otp/send-otp", {
            email: user.email,
          })
          .then((res) => {
            setMode("verify");
          });
      } else {
        const { data: loginData } = await apiClient.post("/api/auth/login", {
          email: user.email,
          password: user.password,
        });
        response = loginData;
      }

      setState({
        data: {
          name: response.data.user.name,
          email: response.data.user.email,
          accounts: response.data.user.accounts,
          access_token: response.data.user.access_token,
          refresh_token: response.data.user.access_token,
          posts: response.data.user.posts,
          renewsAt: response.data.user.renewsAt,
          status: response.data.user.status,
          variantName: response.data.user.variantName,
          variantId: response.data.user.variantId,
          customerPortal: response.data.user.customerPortal,
          videosDownloaded: response.data.user.videosDownloaded,
        },
        loading: false,
        error: null,
      });
      localStorage.setItem("accessToken", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);

      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full relative overflow-hidden flex flex-col items-center justify-start text-left text-sm text-gray-900 font-text-md-medium">
      <div className="self-stretch flex flex-row items-center justify-start">
        <div className="self-stretch flex-1 flex flex-col items-center justify-between">
          <div className="self-stretch flex flex-col items-center justify-start py-0 px-8">
            {mode === "home" ? (
              <Home
                isSignupFlow={isSignupFlow}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            ) : (
              <Verify user={user} handleChange={handleChange} verify={verify} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = ({ isSignupFlow, handleChange, handleSubmit }: any) => {
  const navigate = useNavigate();

  const handleEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const form = event.currentTarget.closest("form");
        form?.requestSubmit();
      }
    },
    []
  );

  return (
    <div className="max-w-[360px] w-full flex flex-col items-center justify-start gap-[32px] py-[32px]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-11xl">
        <div className="self-stretch relative leading-[38px] font-semibold">
          {isSignupFlow ? "Sign up" : "Log in"}
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700"
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
          {isSignupFlow && (
            <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <div className="relative leading-[20px] font-medium">Name*</div>
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => handleChange("name", e.target.value)}
                onKeyDown={handleEnterPress}
                className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-3.5 gap-[8px] text-base border-[1px] border-solid border-gray-300"
              />
            </div>
          )}
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
            <div className="relative leading-[20px] font-medium">Email*</div>
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => handleChange("email", e.target.value)}
              onKeyDown={handleEnterPress}
              className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-3.5 gap-[8px] text-base border-[1px] border-solid border-gray-300"
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
            <div className="relative leading-[20px] font-medium">Password*</div>
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => handleChange("password", e.target.value)}
              onKeyDown={handleEnterPress}
              className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-3.5 gap-[8px] text-base border-[1px] border-solid border-gray-300"
            />
            {isSignupFlow && (
              <div className="self-stretch relative leading-[20px] text-gray-600">
                Must be at least 8 characters.
              </div>
            )}
            {!isSignupFlow && (
              <div className="ml-auto self-stretch relative leading-[20px] text-black">
                <button
                  onClick={() => navigate("/reset-password")}
                  className="relative leading-[20px] font-semibold"
                >
                  Forgot password?
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base text-white">
          <button className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-black py-2.5 px-[18px] border-[1px] border-solid font-semibold">
            {isSignupFlow ? "Sign up" : "Sign in"}
          </button>
          {/*<button className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-4 gap-[12px] border-[1px] border-solid border-gray-300 flex items-center justify-center text-gray-700 font-semibold">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              className="w-6 h-6"
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
            {isSignupFlow ? "Sign up with Google" : "Sign in with Google"}
          </button>*/}
          {/*<GoogleOAuthProvider clientId={env.GOOGLE_CLIENT_ID}>
            <GoogleLogin
              onSuccess={(response) => {
                console.log(response);
                apiClient.post(`/api/auth/google-auth?credential=${response.credential}`);
              }}
              onError={() => {
                toast.error("Internal server error");
              }}
              containerProps={{
                className: "self-stretch",
              }}
            />
          </GoogleOAuthProvider>*/}
        </div>
      </form>
      <div className="self-stretch flex flex-row items-start justify-center gap-[4px] text-gray-600">
        <div className="relative leading-[20px]">
          {isSignupFlow ? "Already have an account?" : "Don't have an account?"}
        </div>
        <div className="flex flex-row items-start justify-start text-black">
          <div className="flex flex-row items-center justify-center">
            <button
              onClick={() =>
                navigate(`/${isSignupFlow ? "login" : "register"}`)
              }
              className="relative leading-[20px] font-semibold"
            >
              {isSignupFlow ? "Log in" : "Sign up"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Verify = ({ user, handleChange, verify }: any) => {
  return (
    <div className="max-w-[360px] w-full flex flex-col items-center justify-start gap-[32px] py-[32px]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-11xl">
        <div className="self-stretch relative leading-[38px] font-semibold">
          Verify email address
        </div>
        <div className="self-stretch relative text-base leading-[24px] text-gray-600">
          A six digit has been sent to your email{" "}
          <span className="font-semibold">{user.email}</span>. Please enter the
          code you received below:
        </div>
      </div>
      <form
        onSubmit={verify}
        className="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700"
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
            <input
              type="password"
              placeholder="Six digit code"
              onChange={(e) => handleChange("otp", e.target.value)}
              className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-3.5 gap-[8px] text-base border-[1px] border-solid border-gray-300"
            />
          </div>
        </div>
        <button className="text-white self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-black py-2.5 px-[18px] border-[1px] border-solid font-semibold">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Auth;

/*<section>
      <div className="layout flex flex-col space-y-16 items-center pt-[124px]">
        <h1 className="text-center text-3xl sm:text-5xl font-semibold text-black">
          {text}
        </h1>
        <form
          onSubmit={handleSubmit}
          className="space-y-8 bg-white flex flex-col p-4 border border-[#D0D5DD] rounded-xl max-w-[676px] w-full"
        >
          {isSignupFlow && (
            <div className="flex flex-col gap-3">
              <div className="flex justify-between">
                <label
                  htmlFor="name"
                  className="font-normal text-sm text-black"
                >
                  Name
                </label>
              </div>
              <input
                ref={nameRef}
                value={user.name}
                onChange={(e) => handleChange("name", e.target.value)}
                type="text"
                id="name"
                name="name"
                className="cursor-auto h-11 py-[10px] px-[12px] border border-[#D0D5DD] rounded-lg bg-[#F9FAFB] outline-none"
              />
            </div>
          )}
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <label htmlFor="email" className="font-normal text-sm text-black">
                Email
              </label>
            </div>
            <input
              ref={emailRef}
              value={user.email}
              onChange={(e) => handleChange("email", e.target.value)}
              type="email"
              id="email"
              name="email"
              className="cursor-auto h-11 py-[10px] px-[12px] border border-[#D0D5DD] rounded-lg bg-[#F9FAFB] outline-none"
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <label
                htmlFor="password"
                className="font-normal text-sm text-black"
              >
                Password
              </label>
            </div>
            <input
              ref={passwordRef}
              value={user.password}
              onChange={(e) => handleChange("password", e.target.value)}
              type="password"
              id="password"
              name="password"
              className="cursor-auto h-11 py-[10px] px-[12px] border border-[#D0D5DD] rounded-lg bg-[#F9FAFB] outline-none"
            />
          </div>
          <button className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black">
            Get Started
          </button>
        </form>
        {errorMsg && errorMsg}
        <button
          onClick={() => navigate(`/${isSignupFlow ? "login" : "register"}`)}
        >
          {isSignupFlow
            ? "Do you already have an account?"
            : "Register a new account"}
        </button>
      </div>
    </section>*/
