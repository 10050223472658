import axios from "axios";
import toast from "react-hot-toast";

const apiClient = axios.create({
  //baseURL: "http://localhost:1337",
  baseURL: "https://socialpostr.com",
});

async function refreshToken() {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await apiClient.post("/api/auth/refresh-token", {
      refreshToken,
    });
    const { accessToken, newRefreshToken } = response.data;

    // Update the access token and refresh token in localStorage
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    // Retry the original API request with the new access token
    return true;
  } catch (error) {
    // Handle token refresh error (e.g., logout the user)
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
    return false;
  }
}

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;

    // Handle token expiration and retry the request with a refreshed token
    if (error.response && error.response.status === 403) {
      const success = await refreshToken();
      if (success) {
        return apiClient(originalRequest);
      }
    }

    if (error.response.data.errors) {
      for (let i = 0; i < error.response.data.errors.length; i++) {
        toast.error(error.response.data.errors[i].message);
      }
    }

    return Promise.reject(error);
  }
);

export { apiClient };
