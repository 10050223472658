/* eslint-disable */
import React from "react";
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Modal from "../components/AddAccount";
import DiscloseVideoContent from "../components/DiscloseVideoContent";
import toast from "react-hot-toast";
import { PostContext, UserContext } from "../context";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import youtube from "../assets/youtube.svg";
import tiktok from "../assets/tiktok.svg";
import pinterest from "../assets/pinterest.svg";
import linkedin from "../assets/linkedin.svg";
import plus from "../assets/plus.svg";
import Settings from "../components/Settings";
import Thumbnail from "../components/Thumbnail";

type PlatformImages = {
  [key: string]: string;
};

const platformImages: PlatformImages = {
  facebook: facebook,
  instagram: instagram,
  youtube: youtube,
  tiktok: tiktok,
  pinterest: pinterest,
  linkedin: linkedin,
};

interface OptionInterface {
  name: string;
  id: string | number;
}

interface RequirementInterface {
  name: string;
  platform: string;
  value: string | null | boolean;
  type: string;
  options?: OptionInterface[];
}

function DateCalendarValue({ date, setDate }: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        sx={{
          height: 300,
          width: "100%",
          position: "flex-start",
          backgroundColor: "white",
          borderRadius: "12px",
          border: "1px solid #d0d5dd",
        }}
        disablePast
        views={["day"]}
        value={date}
        onChange={(e) => setDate(e)}
      />
    </LocalizationProvider>
  );
}

interface SwitchProps {
  value: boolean;
  onChange: () => void;
}

const Switch = (props: SwitchProps) => {
  const toggleClass = "transform translate-x-[19px]";

  return (
    <div
      onClick={props.onChange}
      className={`w-[44px] h-[25px] flex items-center ${
        props.value ? "bg-[#12B76A]" : "bg-gray-300"
      } rounded-full p-[3px] cursor-pointer`}
    >
      {/* Switch options */}
      <div
        className={`bg-white
        w-[19px] h-[19px] rounded-full shadow-md transition transform
        ${props.value ? toggleClass : null}
        `}
      ></div>
    </div>
  );
};

const Image = React.memo(
  ({ index, activeIndex, setActiveIndex, file }: any) => {
    return (
      <div
        key={index}
        className={`bg-cover bg-center rounded-2xl m-2 transition-all duration-500 cursor-pointer ${
          activeIndex === index ? "h-96" : "h-12"
        }`}
        style={{
          backgroundImage: `url(${URL.createObjectURL(file)})`,
        }}
        onClick={() => setActiveIndex(index)}
      />
    );
  }
);

const Post = ({ handleChange, handlePost }: any) => {
  const [post, setPost] = useContext(PostContext);
  const [state, setState] = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [showDiscloseVideoContent, setShowDiscloseVideoContent] =
    useState(false);
  const [settings, setSettings] = useState(post.platforms);
  const [showSettings, setShowSettings] = useState(false);
  const [discloseVideoContent, setDiscloseVideoContent] = useState([]);

  const imagePlatforms = ["instagram", "facebook", "pinterest", "linkedin"];
  const carouselPlatforms = [
    "instagram",
    "facebook",
    "pinterest",
    "linkedin",
    "tiktok",
  ];
  const videoPlatforms = [
    "youtube",
    "instagram",
    "tiktok",
    "linkedin",
    "facebook",
    "pinterest",
  ];

  const otherContentRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const ratioRef = useRef<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        const videoElement = videoRef.current;
        const otherContentElement = otherContentRef.current;
        if (videoElement && otherContentElement) {
          const otherContentWidth = otherContentElement.clientWidth;
          const otherContentHeight = otherContentElement.clientHeight;

          // Calculate new height based on original aspect ratio
          if (ratioRef.current !== null) {
            let newHeight = otherContentWidth * ratioRef.current;

            // Check if the new height exceeds the height of other content
            if (newHeight > otherContentHeight) {
              newHeight = otherContentHeight;
              videoElement.style.width = `${newHeight / ratioRef.current}px`;
            } else {
              videoElement.style.width = `${otherContentWidth}px`;
            }

            videoElement.style.height = `${newHeight}px`;
          }
        }
      }
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      const handleLoadedMetadata = () => {
        if (!ratioRef.current) {
          ratioRef.current = videoElement.videoHeight / videoElement.videoWidth;
          handleResize(); // Ensure initial resize on metadata load
        }
      };

      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      window.addEventListener("resize", handleResize);

      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [post, otherContentRef]);

  const handleChangeRequirement = (name: any, value: any) => {
    const updatedRequirements = post.requirements.map((requirement) => {
      if (requirement.name === name) {
        return { ...requirement, value: value };
      }
      return requirement;
    });

    // Update post state
    setPost((prevPost: any) => ({
      ...prevPost,
      requirements: updatedRequirements,
    }));
  };

  const requirements = post.requirements.reduce(
    (groups: any, requirement: RequirementInterface) => {
      const platform = requirement.platform;
      if (!groups[platform]) {
        groups[platform] = [];
      }
      groups[platform].push(requirement);
      return groups;
    },
    {}
  );

  const [showThumbnail, setShowThumbnail] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const files = useMemo(() => post.files, [post.files]);

  return (
    <section className="flex flex-col items-center py-[64px]">
      <div className="layout flex flex-col items-center">
        <div className="flex flex-col-reverse lg:flex-row lg:gap-[85px] gap-[24px] mt-[57px] rounded-2xl bg-[#FCFCFD] border border-[#D0D5DD] p-[32px]">
          <div
            ref={otherContentRef}
            className="flex flex-col gap-[24px] max-w-[588px] w-full h-fit"
          >
            <textarea
              value={post.caption}
              onChange={(e) => handleChange("caption", e.target.value)}
              placeholder="Description"
              className="outline-none resize-none h-[124px] p-[16px] w-full border border-[#D0D5DD] rounded-[12px]"
            />
            {post.format === "video" && (
              <button
                onClick={() => setShowThumbnail(true)}
                className="bg-white w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
              >
                Add thumbnail
              </button>
            )}
            <div className="flex flex-col gap-[16px] text-black text-base">
              <div className="flex justify-between">
                <p className="font-semibold">Social accounts</p>
                <div className="lg:hidden cursor-pointer flex gap-[2px]">
                  <img src={plus} />
                  <p
                    onClick={() => setModal(true)}
                    className="font-medium text-gray-500"
                  >
                    Add Account
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-[16px]">
                {state.data?.accounts &&
                  state.data.accounts.map((account: any, index: any) => {
                    const platformImage = platformImages[account.platform];
                    return (
                      <div
                        key={index}
                        className="flex flex-col justify-center items-center gap-[8px]"
                      >
                        <div
                          className={`w-[50px] h-[50px] relative ${
                            (post.format === "image"
                              ? imagePlatforms
                              : post.format === "video"
                              ? videoPlatforms
                              : carouselPlatforms
                            ).includes(account.platform)
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                          onClick={() =>
                            (post.format === "image"
                              ? imagePlatforms
                              : post.format === "video"
                              ? videoPlatforms
                              : carouselPlatforms
                            ).includes(account.platform)
                              ? handleChange(
                                  "platforms",
                                  post.platforms.includes(account)
                                    ? post.platforms.filter(
                                        (item: any) => item !== account
                                      )
                                    : [...post.platforms, account]
                                )
                              : toast.error("File format not supported")
                          }
                        >
                          <img
                            src={platformImage}
                            className="border-white border-[2px] rounded-full absolute right-0 bottom-0"
                            width="22"
                            height="22"
                          />
                          <img
                            key={index}
                            className={`border-gray-200 p-[3px] border-3 rounded-full ${
                              post.platforms.includes(account) &&
                              "!border-[#7F56D9]"
                            }`}
                            src={account.image_url}
                            width="50"
                            height="50"
                          />
                        </div>
                        <p
                          className={`whitespace-nowrap text-sm text-gray-500 font-medium ${
                            post.platforms.includes(account) && "!text-black"
                          }`}
                        >
                          {account.name}
                        </p>
                      </div>
                    );
                  })}
                <svg
                  onClick={() => setModal(true)}
                  className="cursor-pointer hidden lg:block mb-[29px]"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="25" cy="25" r="25" fill="#F2F4F7" />
                  <path
                    d="M17.5 25H32.5"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 17.5V32.5"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-[24px]">
              <div className="flex flex-col w-full gap-[24px]">
                <div className="flex flex-col gap-[16px]">
                  <p className="text-black font-semibold text-base">Schedule</p>
                  <DateCalendarValue
                    date={post.date}
                    setDate={(e: any) => handleChange("date", e)}
                  />
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "12px",
                      outline: "none",
                      border: "1px solid #d0d5dd",
                    }}
                    value={post.time}
                    onChange={(e) => handleChange("time", e)}
                  />
                </LocalizationProvider>
                <button
                  onClick={() => handlePost(true)}
                  className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold bg-white"
                >
                  Schedule
                </button>
              </div>
              <div className="min-w-[282px] flex flex-col w-full gap-[24px]">
                <div className="flex flex-col gap-[24px]">
                  <p className="text-black font-semibold text-base">Settings</p>
                  <div className="p-[16px] overflow-y-auto h-[292px] w-full bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
                    {Object.keys(requirements).map((platform, index) => (
                      <div key={index} className="py-[8px]">
                        <h3 className="text-[16px] leading-[28px] font-semibold">
                          {platform} controls
                        </h3>
                        {requirements[platform].map(
                          (
                            requirement: RequirementInterface,
                            reqIndex: number
                          ) => (
                            <div key={reqIndex} className="mt-[16px]">
                              {requirement.type === "dropdown" ? (
                                <div className="my-[8px]">
                                  <h4 className="mt-[8px] font-light">
                                    {requirement.name}
                                  </h4>
                                  <select
                                    onChange={(e) =>
                                      handleChangeRequirement(
                                        requirement.name,
                                        requirement.options?.find(
                                          (option: OptionInterface) =>
                                            option.id === e.target.value
                                        )?.id
                                      )
                                    }
                                    value={
                                      requirement.value !== null &&
                                      typeof requirement.value !== "boolean"
                                        ? requirement.value
                                        : ""
                                    }
                                    className="my-[8px] text-[16px] leading-[24px] font-medium outline-none py-[10px] px-[16px] w-full border-1 border-gray-300 rounded-[8px] bg-gray-50"
                                  >
                                    {requirement.options?.map(
                                      (
                                        option: OptionInterface,
                                        optionIndex: number
                                      ) => (
                                        <option
                                          key={optionIndex}
                                          value={option.id}
                                        >
                                          {option.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              ) : requirement.type === "switch" ? (
                                <div className="w-full flex items-center justify-between">
                                  <p className="text-[16px] leading-[24px] font-medium">
                                    {requirement.name}
                                  </p>
                                  <Switch
                                    value={requirement.value as boolean}
                                    onChange={() =>
                                      handleChangeRequirement(
                                        requirement.name,
                                        typeof requirement.value === "boolean"
                                          ? !requirement.value
                                          : null
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                <div>
                                  <input
                                    value={
                                      typeof requirement.value === "string"
                                        ? requirement.value
                                        : ""
                                    }
                                    className="font-light outline-none p-[16px] w-full border border-[#D0D5DD] rounded-[12px] bg-white"
                                    onChange={(e) =>
                                      handleChangeRequirement(
                                        requirement.name,
                                        e.target.value
                                      )
                                    }
                                    placeholder={requirement.name}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-between items-center h-[56px]">
                    <p className="text-black font-semibold text-base">
                      Disclose video content
                    </p>
                    <Switch
                      value={Boolean(
                        showDiscloseVideoContent || discloseVideoContent.length
                      )}
                      onChange={() =>
                        discloseVideoContent.length
                          ? setDiscloseVideoContent([])
                          : setShowDiscloseVideoContent(true)
                      }
                    />
                  </div>
                </div>

                <button
                  onClick={() => handlePost(false)}
                  className="mt-auto w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
                >
                  Post now
                </button>
              </div>
            </div>
          </div>
          {post.format === "video" ? (
            <video
              ref={videoRef}
              className="rounded-[12px]"
              id="media"
              controls
            >
              <source
                src={URL.createObjectURL(files[0])}
                type={files[0].type}
              />
            </video>
          ) : (
            <div className="w-[558px] max-h-[800px] overflow-y-auto">
              {files &&
                files.map((file: any, index: number) => (
                  <Image
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    file={file}
                  />
                ))}
            </div>
          )}
          {modal && <Modal setModal={setModal} />}
          {showSettings && (
            <Settings
              settings={settings}
              handleChangeRequirement={handleChangeRequirement}
              setShowSettings={setShowSettings}
              requirements={post.requirements}
            />
          )}
          {showDiscloseVideoContent && (
            <DiscloseVideoContent
              discloseVideoContent={discloseVideoContent}
              setDiscloseVideoContent={setDiscloseVideoContent}
              showDiscloseVideoContent={discloseVideoContent}
              setShowDiscloseVideoContent={setShowDiscloseVideoContent}
            />
          )}
          {showThumbnail && (
            <Thumbnail
              post={post}
              setModal={setShowThumbnail}
              source={URL.createObjectURL(post.files[0])}
              type={post.files[0].type}
              handleChange={handleChange}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Post;
